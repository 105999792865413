<template>
  <div class="px-5 py-3 step-info">
    <b-row class="justify-content-between align-items-center h5 my-3">
      <div class="mb-0">{{ conData.title }}
      </div>
      <div>
        <b-button size="small" variant="outline-gray" @click="close()">Close</b-button>
      </div>
    </b-row>

    <b-row align-v="center" no-gutters align-h="between">
      <template v-if="conData.pool">
        <b-col class="jumbotron">
          <div class="caption">{{ conData.pool.from.title }}</div>
          <b-form-row>
            <b-col md="auto" class="w-auto">
              <div>{{ conData.pool.from.value }}</div>
            </b-col>
            <b-col>
              <div class="text-primary">{{ conData.pool.from.name.toUpperCase() }}</div>
            </b-col>
          </b-form-row>
        </b-col>
        <template v-if="conData.pool.to">
          <b-col md="auto" class="text-center my-3 w-auto">
            <svg width="15" height="16" class="mx-2" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.75 9.33333H0.75L7.41667 16V12H12.75V9.33333Z" fill="#D6D6E0"/>
              <path d="M7.41667 0V4H2.08333V6.66667H14.0833L7.41667 0Z" fill="#D6D6E0"/>
            </svg>
          </b-col>
          <b-col class="jumbotron">
            <p class="caption">{{ conData.pool.to.title }}</p>
            <b-form-row>
              <b-col md="auto" class="w-auto">
                <div>{{ conData.pool.to.value }}</div>
              </b-col>
              <b-col>
                <div class="text-primary">{{ conData.pool.to.name.toUpperCase() }}</div>
              </b-col>
            </b-form-row>
          </b-col>
        </template>

      </template>
      <template v-if="conData.voteResult">
        <b-col class="jumbotron">
          <b-form-row>
            <b-col md="auto" class="w-auto">
              <div>Vote</div>
            </b-col>
            <b-col>
              <div class="text-primary">{{ conData.voteResult }}</div>
            </b-col>
          </b-form-row>
        </b-col>

      </template>

    </b-row>
    <b-table small borderless :items="conData.listData" :fields="fields" thead-class="d-none" class="mt-4 mb-0">
      <template v-slot:cell(name)="{value}" class="p-0">
        <span class="caption">{{ value }}</span>
      </template>
      <template v-slot:cell(data)="{item}">
        <span class="mr-3">{{ item.data }}</span><span class="caption">{{ item.dataText }}</span>
      </template>
    </b-table>

  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    conData: {
      title: String,
      pool: {
        from: {
          title: String,
          name: String,
          value: String
        },
        to: {
          title: String,
          name: String,
          value: String
        },
      },
      listData: Array,
    }
  },
  data: function () {
    return {
      fields: ['name', 'data'],
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }

}
</script>

<style scoped lang="scss">

  .step-info {
    .jumbotron {
      background: $gray-100;
      border-radius: 6px;
      margin: 0;
      padding: 16px;
      font-weight: 700;

      .form-row {
        flex-wrap: nowrap;
      }
    }
  }

</style>
